@import '../../scss/variables';

.hero-container{
    padding-bottom: 6em;
    position: relative;
    background: #fff;
    background-image: url('../../assets/images/bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    .homepage-banner-content{
        position: relative;
        width: 100%;
        // color: $dark-color;
        // background: rgb(137,190,81);
        // background: linear-gradient(180deg, rgba(113,168,75,0.6) 0%, rgba(113,168,75,0.6) 13%, rgba(113,168,75,0.6) 100%);
        padding: 120px 20px;
        box-sizing: border-box;
        @include md {
            // background: linear-gradient(180deg, rgba(113,168,75,0.6) 0%, rgba(113,168,75,0.6) 13%, rgba(113,168,75,0.6) 100%);
            padding: 10em 11em 8em;
            width: 65%;
        }
        // @include lg {
        //     padding: 100px 0px;
        // }
        
        h1{
            margin:30px 0 20px;
            line-height: 1.1em;
            color: #fff ;
            font-weight: 600;
            font-size: 2.2em;
            @include md {
                font-size: 2.8em;
            }
        }
        h3{
            font-size: 1em;
            font-weight: 400;
            color: #fff;
            margin:20px 0;
            @include md {
                font-size: 1.2em;
            }
        }
        p{
            color: $text-color;
            width: 80%;
            font-size: 1.3em;
            line-height: 1.7rem;
            @include md {
                width: 80%;
            }
            
        }
        .get-started-btn{
           
        }
    }
}

.about-section{
    background-color: #fff;
    text-align: center;
    @include md {
        padding: 100px 0 80px;
        text-align: left;
    }
    padding: 20px 0 50px;

    h3{
        font-size: 1.3rem;
        font-weight: 600;
    }
    p{
        text-align: center;
        @include md {
            text-align: justify;
        }
        color: $text-color;
        font-size: 1em;
        line-height: 1.9em;
        font-weight: 300;
        span{
            font-weight: 700;
            color: $main-color;
        }
    }
    .showcase-wrapper{
        .showcase-1{
            position: absolute;
            top: -150px;
            display: none;
            img{
                max-width: 100%;
            }
            @include md {
                display: block;
            }
        }
        .showcase-2{
            
            img{
                max-width: 100%;
            }
            @include md {
                position: absolute;
                top: -50px;
                display: block;
            }
        }
    }

    .clients-logos-section {
        margin-top:100px;
        img{
            height: 50px;
            -o-object-fit: contain;
            object-fit: contain;
            margin: 0 15px 50px;
            cursor: pointer;
            filter: grayscale(1);
            transition: all .3s;
            transition-timing-function: linear;
            transition-delay: 0s;
            &:hover{
                -webkit-filter: grayscale(0);
                filter: grayscale(0);
            }
        }
    }
}

.services-section{
    text-align: center;
        @include md {
            text-align: justify;
        }
    // background-color: $mute-color-light;
    background-image: url('../../assets/images/curve-back.png');
    background-position: 753px 0px;
    background-repeat: no-repeat;
    background-size: auto;
    padding: 80px 0;
    .title{
        font-family: $primary-font;
        color: $dark-color;
        font-weight: 700;
        margin-bottom:0.5em;
    }

    .service-item{ 
        box-sizing: border-box;
        margin-bottom: 2em;
        min-height: 120px;
        @include md {
            min-height: 200px;
        }
        .icon{
            width:50px;
            margin: 0 auto;
            @include md {
                margin-left:0;
            }
        }
        .title{
            color:$dark-color;
            font-size: 1em;
            margin: 5px 0;
            font-weight: 600;
            text-transform: uppercase;
        }
        p{
            font-size: 1em;
            font-weight: 300;
            line-height: 1.4em;
        }
    }
}

.callus-section{
    padding: 80px 0;
    background-color: #333;
    background-image: url('../../assets/images/call-us-banner.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    
    h1{
        margin-top: 0;
        color: $white;
        font-size: 1.8rem;
        font-weight: 700;
        word-spacing: 5px;
        // text-transform: uppercase;
    }
    p{ 
        color: $white; 
        font-size: 1.1rem;
        font-weight: 500;
        margin:1em 0;
    }
    .process-item{
        .count{
            color: $white;
            font-size: 22px;
            font-weight: 700;
            line-height: 45px;
            border: 2px solid $white;
            display: inline-block;
            // background-color: transparent;
            background-color: rgba(42, 255, 255, 0.1);
            border-radius: 50% !important;
            animation: bubble 1.2s linear infinite;
            border-radius: 50%;
            width: 45px;
            height: 45px;
            transition: all 0.4s ease-in-out;
            -webkit-transition: all 0.4s ease-in-out;
        }
        h4{
            color:$white;
        }
    }

    a{
        color:$primary-text;
    }
    .description{
        color: $mute-color;
    }
}

.quotation-section{
    background-image: url('../../assets/images/contact-bg.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 80px 0;
    text-align: center;
        @include md {
            text-align: justify;
        }
    h1{
        font-size: 1.8em;
        text-transform: uppercase;
        font-weight: 600;
    }
    p{
        line-height: 1.5rem;
        a{
            color: $secondary-color;
        }
    }
    .contact-info{
        li{
            line-height: 2.2rem;
            color: $text-color;
            a{
                text-decoration: none;
                color: $text-color;
            }
        }
    }
    .form-wrap{
        background-color: $white;
        padding: 50px 20px;
        margin-top: -110px;
        border-radius: 5px;
        box-shadow: 1px 1px 9px 2px rgba(0,0,0,.1);
        .form-group{
            position: relative;
            display: block;
            -webkit-box-flex: 0;
            -ms-flex-positive: 0;
            flex-grow: 0;
            width: 100%;
            margin-bottom: 10px;
            padding: 0;
            border: none;
            .form-select{
                background-color: #fff;
                border-radius: 0;
                position: relative;
                border: 1px solid #ced4da;
                cursor: pointer;
                height: 40px;
                width: 100%;
                padding-left: 17px;
                line-height: 1.5;
            }
            .form-group input[type=text] {
                padding: 0 35px 0 16px;
                height: 40px;
                width: 100%;
                vertical-align: middle;
                color: #323232;
                border: 1px solid rgba(50,50,50,.1);
                -moz-appearance: none;
                -webkit-appearance: none;
            }
            .form-textarea{
                width: 100%;
                padding: 10px 10px 0 12px;
                border: 1px solid rgba(50,50,50,.1);
            }
            .send-btn{
                padding: 15px;
                width: 100%;
                font-weight: 600;
                text-transform: uppercase;
            }
        }
    }
}



// .farm-slider{
//     .slick-prev{
//         color: red;
//     }
//     .slick-next{
//         color: red;
//     }
// }


@keyframes bubble{
    0% {
        box-shadow: 0 0 0 0 rgba(248, 247, 238, 0.1), 0 0 0 5px rgba(233, 228, 247, 0.1), 0 0 0 20px rgba(250, 250, 252, 0.1);
    }
    100% {
        box-shadow: 0 0 0 5px rgba(232, 228, 243, 0.1), 0 0 0 20px rgba(234, 230, 247, 0.1), 0 0 0 30px rgba(235, 234, 238, 0);
    }
}

// @keyframes bubble{
//     0% {
//         box-shadow: 0 0 0 0 rgba(95, 42, 255, 0.1), 0 0 0 10px rgba(95, 42, 255, 0.1), 0 0 0 30px rgba(95, 42, 255, 0.1);
//     }
//     100% {
//         box-shadow: 0 0 0 10px rgba(95, 42, 255, 0.1), 0 0 0 30px rgba(95, 42, 255, 0.1), 0 0 0 50px rgba(95, 42, 255, 0);
//     }
// }

.gradient-btn{
    display: inline-block;
    margin-top:10px;
    text-decoration: none;
    border-radius: 5px;;
    padding:15px 30px; 
    color: #fff;
    font-weight: 600;
    background: linear-gradient(to right, $main-color 0%, $secondary-color 100%);

    &:hover{
        cursor: pointer;
        box-shadow: 3px 3px 10px 2px rgba(0,0,0,.1);
    }
}

.success-msg{
    padding: 50px 0;
}