@import '../scss/media-queries';


$main-color: #00C7BE;
$secondary-color: #00ACD6;
$main-color-light: #35dbe0; 
$main-color-light-2: #81eaee;
$primary-hover:#02BEC6;
$primary-text: #35dbe0;
$dark-color: #1C1C1C;
$text-color: #555;
$danger-color: #c73a21;
$mute-color: rgb(163, 162, 162);
$mute-color-light: #fcfcfc;
$light-grey: rgba(0, 0, 0, 0.1);
$disabled-color: #6c757d;
$white: #ffffff;
$primary-font: 'Poppins', sans-serif;
$secondary-font: 'Roboto', sans-serif;


// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;