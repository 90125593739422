@import '../../scss/variables';

.footer {
    position: relative;
    overflow: hidden;
    padding: 50px 0 0;
    color: $white;
    text-align: center;
    background-color:  rgba(0,0,0,.9);
    &:after{
        display: none;
        @include md {
            display: block;
        }
        content: '';
        background-image: url('../../assets/logo.svg');
        background-repeat: no-repeat;
        width: 100%;
        height: 400px;
        position: absolute;
        top:-20px;
        right: -80%;
        opacity: .05;
    }
    
    .container{
        .logo-wrapper{
            width:200px; 
            margin: 0 auto;
            @include md {
                width:300px; 
            }
        }
        h2{
            text-transform: uppercase;
            font-family: $primary-font;
            font-size: 1.2rem;
            font-weight:600;
            margin-bottom: 20px ;
            margin-top:50px;
            @include md {
                margin-top:10px;
            }
        }
        .quicklinks{
            @include md {
            }
            li{
                display: inline-block;
                margin-bottom: 20px;
                font-weight:300;
                &:hover{
                    cursor:pointer;
                    color: $primary-hover;
                }
                a{
                    display: block;
                    padding: .3em;
                    margin: 5px;
                    text-decoration: none;
                    color: white;
                    &:hover, &:active, &.active{
                        @include md {
                            cursor:pointer;
                            color: $primary-hover;
                            background-color: transparent;
                        }
                        cursor:pointer;
                        color: $primary-hover;
                    }
                }
            }
        }
        .contact{
            li{
                margin-bottom: 20px;
                line-height: 20px;
                a{
                    color: $white;
                    text-decoration: none;
                    font-weight:300;
                    margin-bottom:15px;
                    i{
                        font-size:12px;
                    }
                    &:hover{
                        cursor:pointer;
                        color: $primary-hover;
                    }
                    &.tel{
                        display: inline-block;
                        flex-direction: column;
                    }
                }
            }
        }
        .socials{
            li{
                display: inline-block;
                a{
                    color: $white;
                    text-decoration: none;
                    margin-right:10px;
                    &:hover{
                        cursor:pointer;
                        color: $primary-hover;
                    }
                }
            }
        }
    }

    .bottom-footer{
        background-color: rgba(0,0,0,.4);
        font-size: 0.8rem;
        color: #777;
        text-align: center;
        margin-top: 20px;
        padding: 20px 0;
    }

// 576px window width and more
    @include sm {
        // padding: 0 20px;
    }
// 992px window width and more
    @include lg {
        // padding: 0 20px;
    }
// 1400px window width and more
    @include rwd(1400) {
        
    }
}

// .navigation {
//     padding: 0 15px;
// // 576px window width and more
//     @include sm {
//         padding: 0 20px;
//     }
// // 992px window width and more
//     @include lg {
//         margin-left: auto;
//         margin-right: auto;
//         max-width: 1100px;
//     }
// // 1400px window width and more
//     @include rwd(1400) {
//         margin-bottom: 20px;
//         margin-top: 20px;
//     }
// }
