@import '../../scss/variables';

.prev{
    position: absolute;
    display: block;
    top: 40%;
    left: -80px;
    color: $main-color;
    box-shadow: 1px 1px 2px 1px #ededed;
    background-color: #fff;
    z-index: 5;
    height:50px;
    font-size: 40px;
    width: 50px;
    border-radius: 50%;
    text-align: center;
    line-height: 50px;
}

.next{
    position: absolute;
    display: block;
    top: 40%;
    right: -80px;
    color: $main-color;
    box-shadow: 1px 1px 2px 1px #ededed;
    background-color: #fff;
    z-index: 5;
    height:50px;
        width: 50px;
        font-size: 40px;
    border-radius: 50%;
    text-align: center;
    line-height: 50px;
}