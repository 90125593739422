@import 'scss/styles';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.loader{
  position: relative;
  width: 100%;
  height: 100%;
  .loading-indicator{
    position: absolute;
    display: block;
    height: 50px;
    width: 50px;
    border: 5px solid  $main-color;
    border-top: 5px solid #ededed;
    border-radius: 50%;
    top: calc(50vh - 25px);
    left: calc(50vw - 25px);
    animation-name: spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

@keyframes spin{
  from{
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
}