@import '../../scss/variables';

.navigation {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    padding: 10px 20px;
    box-sizing: border-box;
    &.fixed-nav{
        z-index:99;
        background-color: #fff;
        box-shadow: 2px 2px 3px rgba(0,0,0,.1);
        position: fixed;
        top:0;
        width:100%;
        .main-nav{
            .logo{
                .logo-light{
                    display: none;
                }
                .logo-dark{
                    display: block;
                }
            }
            .open-btn{
                i{
                    color: #111;
                }
            }

        }
        @include md {
            padding: 5px 20px 0;
            .main-nav{
                .nav-menu{
                    ul{
                        display:flex;
                        flex-direction:column;
                        width:100%;
                        @include md {
                            flex-direction:row;
                        }
                        li{
                            a{
                                color: $dark-color;
                            }
                        }
                    }
                }
            }
            .get-started-btn{
                border: 2px solid $dark-color !important;
                padding:10px 30px; 
                font-weight: 700;
                text-transform: uppercase;
                border-radius: .4em;
                color: $dark-color !important;
                &:hover, &:active{
                    @include md {
                        background-color: $main-color;
                        color: $dark-color !important;
                    }
                }
            }
            
        }
    }
    .main-nav{
        // padding: 3px 100px;
        margin-left: auto;
        margin-right: auto;
        /* max-width: 1100px; */
        display: flex;

        // padding: 10px 0px;
        // margin-left: auto;
        // margin-right: auto;
        // max-width: 1100px;
        // display: flex;
        .logo{
            .logo-dark{
                display: none;
            }
        }
        @include md {
            padding: 3px 150px;
            flex-direction: row;
        }
    
        .nav-menu{
            display: none;
            position:fixed;
            top:0;
            left:0;
            right:0;
            bottom: 0;
            padding: 100px 0;
            z-index: 5;
            transition: 2s ease-in-out;
            &.is-open{
                display: flex;
                animation: fadeOpen 0.3s ease-in;
                @include md {
                    background: none;
                }
                background-color: $white;
                
            }
            
            @include md {
                display: flex;
                margin-left: 30px;
                
            }
            
            ul{
                display:flex;
                flex-direction:column;
                width:100%;
                @include md {
                    flex-direction:row;
                }
                li{
                    a{
                        color: $dark-color;
                        font-size: 2rem;
                        text-decoration: none;
                        display: block;
                        padding:20px;
                        text-align: center;
                        @include md {
                            color:#fff; 
                            font-size: 1rem;
                            display: inline-block;
                        }
                        padding:20px;
                        &:hover, &.active{
                            @include md {
                                cursor:pointer;
                                color: $primary-hover;
                                background-color: transparent;
                            }
                            cursor:pointer;
                            background-color: $main-color;
                            color: $dark-color;
                        }

                        &.display-mobile{
                            display:block;
                            @include md {
                                display: none;
                            }
                        }
            
                    }
                }
            }
            
            @include md {
                padding: 0;
                position: static;

            }

            .close-btn{
                position: absolute;
                top:30px;
                left: calc(50% - 13.5px);
                @include md {
                    display: none;
                }
                &:hover{
                    cursor: pointer;
                }
            }
        }

        .action{
            margin-left: auto;
            display:flex;
            flex-direction: row;
        }

        .sign-in-btn{
            padding:20px; 
            margin-right: 10px;
            text-decoration: none;
            color: #fff;
            &:hover, &:active, &.active{
                @include md {
                    cursor:pointer;
                    // color: $primary-hover;
                    text-decoration: underline !important;
                    line-height: 20px;
                }
                cursor:pointer;
                // background-color: $primary-hover;
                text-decoration: underline !important;
            }
        }

        .get-started-btn{
            border: 2px solid #fff;
            padding:10px 30px; 
            font-weight: 700;
            text-transform: uppercase;
            border-radius: .4em;
            color: #fff;
            &:hover, &:active{
                @include md {
                    color: $dark-color;
                    background-color: #fff;
                }
            }
        }

        .get-started-btn, .sign-in-btn{
            align-self: center;
            display:none;
            @include md {
                display:block;
            }
            &:hover, &:active{
                @include md {
                    text-decoration: none;
                    cursor:pointer;
                }
            }
        }
        .open-btn{
            margin-left: auto;
            
            box-sizing: border-box;
            padding-top: 10px;
            height: 50px;
            display: block;
            i{
                color: #fff;
            }
            @include md {
                display: none;
            }
        }
    }
    

// 576px window width and more
    @include sm {
        padding: 0 10px;
    }
    // 768px window width and more
    @include md {
        padding: 20px 20px 0;
    }
// 992px window width and more
    @include lg {
        // padding: 0 20px;
    }
// 1400px window width and more
    @include rwd(1400) {
        
    }
}

@keyframes fadeOpen {
    0% {
        opacity: 0;
    }
    50% {
        opacity: .7;
    }
    100% {
        opacity: 1;
        display:block;
    }
}
