@import 'scss/reset';
@import 'scss/variables';
@import 'scss/bootstrap';
@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

body{
    font-family: $primary-font;
}

strong{
    font-weight: 400;
}
h1 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: $primary-font, sans-serif;
    font-size: 44px;
    line-height: 45px;
    font-weight: 700;
    letter-spacing: -0.5px;
  }
  
  h2 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: $primary-font, sans-serif;
    font-size: 30px;
    line-height: 33px;
    font-weight: 700;
    letter-spacing: -0.4px;
  }
  
  h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: $primary-font, sans-serif;
    font-size: 24px;
    line-height: 27px;
    font-weight: 700;
  }
  
  h4 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: $primary-font, sans-serif;
    font-size: 18px;
    line-height: 23px;
    font-weight: 700;
  }
  
  h5 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: $primary-font, sans-serif;
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
    letter-spacing: 0.5px;
  }
  
  h6 {
    margin-top: 0px;
    margin-bottom: 10px;
    font-family: $primary-font, sans-serif;
    font-size: 11px;
    line-height: 18px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  
  p {
    margin-bottom: 10px;
    font-family: $secondary-font, sans-serif;
  }
  
  a {
    -webkit-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;
    text-decoration: none;
  }
  
